<template>
  <div class="outer">
    <div class="wrapper--top">
      <v-row class="mt-4" align="center" justify="center"> </v-row>
      <v-row class="mt-4" align="center" justify="center">
        <v-col cols="6" align-self="center">
          <h5 class="uppercase text-center font-weight-bold text-h5">
            Film Processing and Dailies online form
          </h5>
        </v-col>
      </v-row>
      <v-row
        v-if="currentStep != 7"
        class="pb-0"
        align="center"
        justify="center"
      >
        <v-col cols="8">
          <v-stepper
            v-model="currentStep"
            alt-labels
            class="stepper"
            flat
            tile
            non-linear
          >
            <v-stepper-header class="stepper">
              <v-stepper-step editable step="1" @click="nextStep(1)">
                Start
              </v-stepper-step>

              <v-divider></v-divider>
              <v-stepper-step editable step="2" @click="nextStep(2)">
                About
              </v-stepper-step>

              <v-divider></v-divider>

              <v-stepper-step editable step="3" @click="nextStep(3)">
                Details
              </v-stepper-step>

              <v-divider></v-divider>

              <v-stepper-step editable step="4" @click="nextStep(4)">
                Info
              </v-stepper-step>
              <v-divider></v-divider>

              <v-stepper-step editable step="5" @click="nextStep(5)">
                Delivery
              </v-stepper-step>
              <v-divider></v-divider>

              <v-stepper-step editable step="6" @click="nextStep(6)">
                Summary
              </v-stepper-step>
            </v-stepper-header>
          </v-stepper>
        </v-col>
      </v-row>
    </div>

    <div id="wrapper">
      <v-row id="page" align="center" justify="center">
        <router-view
          @onChangeProjectAbout="saveOrder($event)"
          @onChangeProcessingDetails="saveOrder($event)"
          @onChangeProjectNotes="saveOrder($event)"
          @nextStep="nextStep($event.n)"
          @clearOrder="clearOrder"
          @startOver="startOver"
          :order="order"
        />

        <RequestType
          :order="getOrder"
          v-if="currentStep == 1"
          @onChange="onSetOrderType($event)"
          @onChangeStudent="onSetOrderFor($event)"

        />
        <v-row
          v-if="currentStep == 1"
          class="pt-12"
          align="center"
          justify="center"
        >
          <v-col cols="6" class="d-flex justify-end">
            <v-btn
              right
              plain
              outlined
              :disabled="!getOrder.orderType"
              @click="nextStep(2)"
            >
              Next
            </v-btn>
          </v-col>
        </v-row>
      </v-row>
      <Footer :copyright="copyright" />
    </div>
  </div>
</template>

<script>
import Footer from '@/components/Footer.vue'
import RequestType from '@/components/RequestType'
import { mapActions, mapGetters, mapState } from 'vuex'
export default {
  name: 'home',
  title: 'Home',
  components: {
    RequestType,
    Footer
  },
  data: function () {
    return {
      copyright: 'Foto-Kem Industries, Inc. All rights reserved.',
      currentStep: 1,
      steps: 6,
      order: { ...this.getOrder }
    }
  },
  computed: {
    ...mapState('snackbar', {
      snackbars: (state) => state.snackbars
    }),
    ...mapGetters({
      getOrder: 'order/getOrder'
    })
  },
  watch: {
    steps (val) {
      if (this.currentStep > val) {
        this.currentStep = val
      }
    },
    $route: 'checkParams'
  },
  async created () {
    this.checkParams()
  },
  methods: {
    ...mapActions('snackbar', ['setSnackbar']),
    ...mapActions('order', ['setOrder', 'setOrderType', 'setOrderFor']),
    onSetOrderType (event) {
      this.setOrderType({ orderType: event.orderType })
    },
    onSetOrderFor (event) {
      this.setOrderFor({ orderFor: event.orderFor })
    },
    saveOrder (event) {
      const order = { ...this.getOrder, ...event.order }
      this.setOrder({ order: order })
    },
    startOver () {
      this.setOrder({
        order: {
          uuid: null,
          orderType: null,
          orderFor: 'Non-student Project',
          projectTitle: null,
          projectType: null,
          shootLocation: null,
          shootDates: [],
          dropDate: null,
          film: [],
          scanning: {
            editorialMedia: 'No'
          },
          client: {
            fullName: null,
            title: null,
            organization: null,
            existingAccount: 'Unsure',
            accountCode: null,
            phone: null,
            email: null,
            contactMethod: [],
            contactCall: false,
            contactText: false,
            contactEmail: false
          },
          delivery: {
            type: null,
            shippingAddress: null,
            shippingMethod: null,
            shippingAccount: null
          }
        }
      })
      this.currentStep = 1
      this.$router
        .push({
          name: 'Home',
          params: {}
        })
        .catch(() => {})
    },
    clearOrder () {
      this.setOrder({
        order: {
          uuid: null,
          orderType: null,
          orderFor: 'Non-student Project',
          projectTitle: null,
          projectType: null,
          shootLocation: null,
          shootDates: [],
          dropDate: null,
          film: [],
          scanning: {},
          client: {
            fullName: null,
            title: null,
            organization: null,
            existingAccount: 'Unsure',
            accountCode: null,
            phone: null,
            email: null,
            contactMethod: [],
            contactCall: false,
            contactText: false,
            contactEmail: false
          },
          delivery: {
            type: null,
            shippingAddress: null,
            shippingMethod: null,
            shippingAccount: null
          }
        }
      })
    },
    nextStep (n) {
      window.scrollTo(0, 0)
      if (n === this.steps) {
        this.currentStep = 1
      } else {
        this.currentStep = parseInt(n)
      }
      if (n === 1) {
        this.$router
          .push({
            name: 'Home',
            params: {}
          })
          .catch(() => {})
      }
      if (n === 2) {
        this.$router
          .push({
            name: 'About',
            params: {}
          })
          .catch(() => {})
      }
      if (n === 3) {
        this.$router
          .push({
            name: 'Details',
            params: {}
          })
          .catch(() => {})
      }
      if (n === 4) {
        this.$router
          .push({
            name: 'Info',
            params: {}
          })
          .catch(() => {})
      }
      if (n === 5) {
        this.$router
          .push({
            name: 'Delivery',
            params: {}
          })
          .catch(() => {})
      }
      if (n === 6) {
        this.$router
          .push({
            name: 'Summary',
            params: {}
          })
          .catch(() => {})
      }
      if (n === 7) {
        this.$router
          .push({
            name: 'Confirmation',
            params: {}
          })
          .catch(() => {})
      }
    },
    async checkParams () {
      this.order = this.getOrder
      switch (this.$route.name) {
        case 'About':
          this.currentStep = 2
          break
        case 'Details':
          this.currentStep = 3
          break
        case 'Info':
          this.currentStep = 4
          break
        case 'Delivery':
          this.currentStep = 5
          break
        case 'Summary':
          this.currentStep = 6
          break
        case 'Confirmation':
          this.currentStep = 7
          break
        default:
          this.currentStep = 1
          break
      }
    }
  }
}
</script>
<style lang="scss">
.outer {
  position: fixed;
  padding: 0;
  width: 0;
  height: 0;
}
#page {
  position: fixed;
  top: 274px;
  bottom: 40px;
  left: 0;
  right: 0;
  overflow: auto;
}
#wrapper {
  position: fixed;
  top: 274px;
  bottom: 40px;
  left: 0;
  right: 0;
  overflow: hidden;
}
.wrapper--top {
  position: fixed;
  top: 74px;
  height: 200px;
  left: 0;
  right: 0;
  overflow: auto;
}
.striped {
  tbody tr:nth-of-type(even) {
    background-color: rgba(55, 55, 55, 0.05);
  }
  tbody tr:nth-of-type(odd) {
    background-color: rgba(0, 0, 0, 0.02);
  }
}
.uppercase {
  text-transform: uppercase;
}
.stepper {
  background-color: transparent !important;
}
</style>
