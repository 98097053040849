<template>
  <v-footer
    class="nl-app-footer"
    color="transparent"
    tile
    padless
    fixed
  >
    <v-row no-gutters class="d-flex">
      <v-btn
        class="nl-app-footer--btn"
        icon
        small
        @click.stop="$vuetify.theme.dark = !$vuetify.theme.dark"
      >
        <v-icon
          small
          :class="
            $vuetify.theme.dark ? 'primary--text' : 'grey--text text--darken-2'
          "
        >
          mdi-weather-night
        </v-icon>
      </v-btn>
      <v-spacer />
      <div class="text-right white--text">
        <span v-if="copyright" class="grey--text caption mr-0">
          &copy; {{ new Date().getFullYear() }} {{ copyright }}
        </span>
      </div>
    </v-row>
  </v-footer>
</template>

<script>
export default {
  name: 'Footer',
  props: {
    copyright: String
  },
  computed: {},
  methods: {}
}
</script>
<style lang="scss" scoped>
.nl-app-footer {
    background-color: transparent;
  height: 36px;
  padding: 2px 15px 4px;
  z-index: 1001;
}
.nl-app-footer--btn {
  margin-left: 0 !important;
  margin-right: 8px;
}
</style>
