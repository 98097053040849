<template>
  <v-col cols="12">
    <v-row class="pt-12" align="center" justify="center">
      <v-col cols="12">
        <h4 class="text-center text-h4">How can we help?</h4>
      </v-col>
    </v-row>
    <v-row class="pt-4" align="center" justify="center">
      <v-radio-group @change="onChange" v-model="orderType">
        <v-radio label="Film Processing & Scanning" value="Processing & Scanning"></v-radio>
        <v-radio label="Film Processing Only" value="Processing Only"></v-radio>
        <v-radio label="Film Scanning Only" value="Scanning Only"></v-radio>
      </v-radio-group>
    </v-row>
  </v-col>
</template>

<script>
export default {
  name: 'RequestType',
  data: function () {
    return {
      orderType: this.order.orderType
    }
  },
  props: {
    order: {
      type: Object
    }
  },
  methods: {
    onChange () {
      this.$emit('onChange', {
        orderType: this.orderType
      })
    },
    checkParams () {
      this.orderType = this.order.orderType
    }
  }
}
</script>

<style scoped lang="scss"></style>
